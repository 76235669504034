// Here you can add other styles
* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: sans-serif;
}

body,
html,
.App,
#root {
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
}
