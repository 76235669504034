html body .app.flex-row.align-items-center {
  height: fit-content;
  min-height: 100vh;
}

/* Hide scrollbar for Chrome,
Safari and Opera */
html body .app.flex-row.align-items-center::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.html body .app.flex-row.align-items-center {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  font-family: VisueltPro-Regular, "Roboto Mono", Consolas, Menlo, Courier,
  monospace;
}

@font-face {
  font-family: "VisueltPro-Medium";
  src: local("VisueltPro-Medium"),
    url(./assets/fonts/VisueltPro-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "VisueltPro-Bold";
  src: local("VisueltPro-Bold"),
    url(./assets/fonts/VisueltPro-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "VisueltPro-Regular";
  src: local("VisueltPro-Regular"),
    url(./assets/fonts/VisueltPro-Regular.ttf) format("truetype");
}
